import { withOidcSecure } from '@axa-fr/react-oidc-context'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from "react"

import loadable from '@loadable/component'
import LoadingProgress from "../components/Misc/LoadingProgress"
import usePermissions from "./usePermissions"

const Organizations = loadable(() => import('../components/Organizations/Organizations'), {
    fallback: <LoadingProgress />,
});

const Resellers = loadable(() => import('../components/Resellers/Resellers'), {
    fallback: <LoadingProgress />,
});

const PlanList = loadable(() => import('../components/LicensePlans/PlanList'), {
    fallback: <LoadingProgress />,
});

const CreatePlan = loadable(() => import('../components/LicensePlans/CreatePlan'), {
    fallback: <LoadingProgress />,
});

const ModifyPlan = loadable(() => import('../components/LicensePlans/ModifyPlan'), {
    fallback: <LoadingProgress />,
});


const PlanDetails = loadable(() => import('../components/LicensePlans/PlanDetails'), {
    fallback: <LoadingProgress />,
});

const LicenseDetailView = loadable(() => import('../components/License/LienseDeatilsMainView'), {
    fallback: <LoadingProgress />,
});

const LicenseListView = loadable(() => import('../components/License/LicenseList'), {
    fallback: <LoadingProgress />,
});

const OrdersListView = loadable(() => import('../components/Orders/OrderList'), {
    fallback: <LoadingProgress />,
});

const OrdersDetailView = loadable(() => import('../components/Orders/OrderDetails'), {
    fallback: <LoadingProgress />,
});

const OrganizationDetailView = loadable(() => import('../components/Organizations/OrganizationDetailsView'), {
    fallback: <LoadingProgress />,
});

const Users = loadable(() => import('../components/Users/Users'), {
    fallback: <LoadingProgress />,
});

const Devices = loadable(() => import('../components/Devices/Devices'), {
    fallback: <LoadingProgress />,
});

const Summary = loadable(() => import('../components/Summary/Summary'), {
    fallback: <LoadingProgress />,
});

const ApkManagement = loadable(() => import('../components/Apps/ApkManagement'), {
    fallback: <LoadingProgress />,
});

const useRedirect = () => {

    const [routes, setRoutes] = useState([])

    const [isComponentMounted, setIsComponentMounted] = useState(false)

    const permission = usePermissions()

    useEffect(() => {
        if (!isEmpty(permission) && !isComponentMounted) setIsComponentMounted(true)
    }, [permission])

    useEffect(() => {
        // Only create/update routes initially, not again 
        // Which conflicts with protected routes resulting in ummounting and mounting then=m again
        if (isComponentMounted) setRoutes(dynamicRoutes)

    }, [isComponentMounted]);

    const dynamicRoutes = [
        {
            id: 1,
            component: withOidcSecure(Organizations),
            path: '/organizations',
            default: true,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 2,
            component: withOidcSecure(LicenseDetailView),
            path: '/organizations/:orgId/licenses/:licenseId/show',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 3,
            component: withOidcSecure(LicenseListView),
            path: '/organizations/:orgId/licenses',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 4,
            component: withOidcSecure(OrdersListView),
            path: '/organizations/:orgId/orders',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 5,
            component: withOidcSecure(OrdersDetailView),
            path: '/organizations/:orgId/orders/:orderId/show',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 6,
            component: withOidcSecure(OrganizationDetailView),
            path: '/organizations/:orgId/details',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 7,
            component: withOidcSecure(PlanList),
            path: '/plans',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 7,
            component: withOidcSecure(CreatePlan),
            path: '/plans/create-plan',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 8,
            component: withOidcSecure(ModifyPlan),
            path: '/plans/:licensePlanShortCode/modify-plan',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 9,
            component: withOidcSecure(PlanList),
            path: '/organizations/:orgId/plans',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 10,
            component: withOidcSecure(PlanDetails),
            path: '/plans/:licensePlanShortCode/details',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 11,
            component: withOidcSecure(PlanDetails),
            path: '/organizations/:orgId/plans/:licensePlanShortCode/details',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 12,
            component: withOidcSecure(ModifyPlan),
            path: '/organizations/:orgId/plans/:licensePlanShortCode/modify-plan',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 13,
            component: withOidcSecure(CreatePlan),
            path: '/organizations/:orgId/plans/create-plan',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 14,
            component: withOidcSecure(Users),
            path: '/organizations/:orgId/users',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 15,
            component: withOidcSecure(Summary),
            path: '/summary',
            default: false,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 16,
            component: withOidcSecure(Resellers),
            path: '/resellers',
            default: true,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 17,
            component: withOidcSecure(Devices),
            path: '/devices',
            default: true,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 18,
            component: withOidcSecure(Organizations),
            path: '/resellers/:resellerId/organizations',
            default: true,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 19,
            component: withOidcSecure(ApkManagement),
            path: '/apk',
            default: true,
            exact: true,
            redirect: (!isEmpty(permission) && !permission.IC_MANAGEMENT) || false
        },
        {
            id: 20,
            component: withOidcSecure(Summary),
            path: '/',
            default: false,
            exact: true,
            redirect: true
        },
    ]

    return { routes }
}

export default useRedirect