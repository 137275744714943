import React, { Fragment, useState } from 'react';
import {
    Typography,
    IconButton,
    Popover,
    makeStyles,
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        minWidth: 300,
        fontSize: '1rem',
    },

    name: {
        marginLeft: 15,
        fontWeight: 500,
    },

    plain: {
        marginLeft: 15,
    },

    avatar: {
        background: theme.palette.primary.main,
    },

    button: {
        fontSize: 14,
    },

    mr24: {
        marginRight: 24
    }
}));

function ListItemLink(props) {
    return <ListItem button component="button" {...props} />;
}

export default props => {
    const { profile, logout } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = e => {
        setAnchorEl(e.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    // TODO: error handling
    function logOut() {
        cleanUpBeforeExit()

        logout()
    }

    /**
     * Cleans up after local storage when session is ending
     */
    function cleanUpBeforeExit() {
        // Reset the snack visible for new logged in admin
        localStorage.setItem("beta-snack-dissmissed", "false");
    }

    return (
        <Fragment>
            <IconButton edge="start" color="inherit" onClick={handlePopoverOpen} className={classes.mr24}>
                <AccountCircleIcon />
            </IconButton>

            {profile.name && (
                <Popover
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    open={open}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <Box className={classes.root}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Avatar className={classes.avatar} alt={profile.name}>
                                {profile.name.slice(0, 1)}
                            </Avatar>
                            <Box>
                                <Typography className={classes.name}>{profile.name}</Typography>
                                <Typography className={classes.plain}>{profile.email}</Typography>
                                {/* <Typography className={classes.plain}>{profile.orgname}</Typography> */}
                            </Box>
                        </Box>
                        <Divider style={{ marginTop: 10 }} />
                        <List component="nav" aria-label="main account logout">
                            {/* <ListItemLink onClick={goToMyProfile} className={classes.button}>
                                Your Account
                            </ListItemLink> */}
                            <ListItemLink onClick={logOut} className={classes.button}>
                                Logout
                            </ListItemLink>
                        </List>
                    </Box>
                </Popover>
            )}
        </Fragment>
    );
};
