/* Action Types */
const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
const TOGGLE_PERSISTANT_SNACK = 'TOGGLE_PERSISTANT_SNACK'
const TOGGLE_ONBOARDING_DIALOG_VISIBILITY = 'TOGGLE_ONBOARDING_DIALOG_VISIBILITY'
const SET_ALL_ORG_OWNER_LIST = 'SET_ALL_ORG_OWNER_LIST'
const SET_ORG_SETTINGS = "SET_ORG_SETTINGS"

export default { 
    SHOW_SNACKBAR,
    TOGGLE_PERSISTANT_SNACK,
    TOGGLE_ONBOARDING_DIALOG_VISIBILITY,
    SET_ALL_ORG_OWNER_LIST,
    SET_ORG_SETTINGS
}