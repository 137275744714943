import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar } from '@material-ui/core'
import Logo from '.././assets/images/Logo/Oneteam_white.svg'
import { NavLink } from 'react-router-dom';
import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context'
import UserNav from './UserNav';
import { useEffect } from 'react';

const event = new CustomEvent('AccessTokenRenewError', { detail: { } });

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#1c5bff'
    },

    menuButton: {
        marginRight: 36,
    },

    hide: {
        display: 'none',
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },

    logo: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#fff',
        textDecoration: 'none',
        marginLeft: 15
    },

    spaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },

}));

const drawerWidth = 240;

const NavigationBar = (props) => {
    const classes = useStyles()
    const { oidcUser, logout, events } = useReactOidc()
    const { profile } = oidcUser
    
    // function togglePersistantSnackbar(isVisible, message, variant = "info") {
    //     dispatch({
    //         type: GlobalStateMutations.TOGGLE_PERSISTANT_SNACK,
    //         payload: {
    //             isVisible: isVisible,
    //             message: message,
    //             variant: variant,
    //         },
    //     });
    // }

    useEffect(()=> {
        events.addAccessTokenExpired(function(data) {
            document.dispatchEvent(event);
        })

        return function cleanup() {
            events.removeAccessTokenExpired(document.dispatchEvent(event));
        }
    }, [])
    
    return (
        <AppBar
            position="fixed"
            className={classes.appBar}>
            <Toolbar className={classes.spaceBetween} disableGutters>
                <div className={classes.alignCenter}><NavLink to="/organizations" className={classes.logo}><img src={Logo} alt="logo" height="30px" className={classes.loadingImg} /></NavLink></div>
                <UserNav profile={profile} logout={logout} />
            </Toolbar>
        </AppBar>
    )
}

export default withOidcSecure(NavigationBar)