import React, { createContext, useReducer } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useEffect } from 'react';
import GlobalStateMutations from './GlobalStateMutations';

const position = {
    vertical: 'bottom',
    horizontal: 'right',
}

const persistantSnackposition = {
    vertical: 'top',
    horizontal: 'center',
}

/* Define a context and a reducer for updating the context */
const GlobalStateContext = createContext();

const initialState = {
    snack: {
        isVisible: false,
        message: '',
        variant: 'success',
    },
    persistantSnack: {
        isVisible: false,
        message: '',
        variant: 'info',
    },
    allOrgOwners: [],
    orgSettings:{},
    isOnboargingDialogVisible: false // weather onboarding dialog is visible or not
};

const globalStateReducer = (state, action) => {
    switch (action.type) {
        case GlobalStateMutations.SHOW_SNACKBAR:
            return {
                ...state,
                snack: { ...action.payload },
            };
        case GlobalStateMutations.TOGGLE_PERSISTANT_SNACK:
            return {
                ...state,
                persistantSnack: { ...action.payload },
            };
        case GlobalStateMutations.TOGGLE_ONBOARDING_DIALOG_VISIBILITY:
            return {
                ...state,
                isOnboargingDialogVisible: action.payload,
            };
        case GlobalStateMutations.SET_ALL_ORG_OWNER_LIST:
            return {
                ...state,
                allOrgOwners: action.payload,
            };
        case GlobalStateMutations.SET_ORG_SETTINGS:
            return {
                ...state,
                orgSettings: action.payload,
            };
        default:
            return state;
    }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        globalStateReducer,
        initialState
    );

    const {
        isVisible,
        message,
        variant
    } = state.snack

    function closeErrorSnack() {
        dispatch({
            type: GlobalStateMutations.SHOW_SNACKBAR,
            payload: {
                isVisible: false,
                message: '',
                variant: 'success',
            }
        });
    }

    /**
     * Closes the persistant snackbar
     * If it is closed for first time, we won't show it again
     * So here we also set the dismiss boolean in localstorage as dismissed
     */
    function closePersistantSnack() {
        // Set snack dismissed in local storage
        localStorage.setItem("beta-snack-dissmissed", "true");
        // And close the snack
        dispatch({
            type: GlobalStateMutations.TOGGLE_PERSISTANT_SNACK,
            payload: {
                isVisible: false,
                message: '',
                variant: 'info',
            }
        });
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // Initialize the variable in localstorage if it doesnot exist
    // For the persistant snackbar for admin
    useEffect(() => {
        checkIfSnackVarInitialized()
    }, [])

    function checkIfSnackVarInitialized() {
        if (localStorage.getItem("beta-snack-dissmissed") == null) {
            localStorage.setItem("beta-snack-dissmissed", "false");
        }
    }


    return (
        <GlobalStateContext.Provider value={{ state, dispatch }}>
            {children}
            {/* We will have this snackbar below app componenet to be reused
            It will be alive till app mounted state i.e. all the time */}
            <Snackbar autoHideDuration={6000} anchorOrigin={position} open={isVisible} onClose={closeErrorSnack}>
                <Alert onClose={closeErrorSnack} severity={variant}>
                    {message}
                </Alert>
            </Snackbar>
            {/* This is a persistant snackbar for admin using localstorage to be visible or not */}
            <Snackbar anchorOrigin={persistantSnackposition} open={state.persistantSnack.isVisible}>
                <Alert onClose={closePersistantSnack} severity={state.persistantSnack.variant}>
                    {state.persistantSnack.message}
                </Alert>
            </Snackbar>
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateContext