import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
/**
 * Section header component for every page inside main
 */
const useStyles = makeStyles(theme => ({

    root: {
        height: 'calc(100% - 65px)',
        maxWidth: "1200px"
    },

  }))

const PageContainer = (props) => {
    
    const classes = useStyles()
    return (
        <Container fixed className={classes.root}>
            {props.children}
        </Container>
    )
}

PageContainer.defaultProps = {
}
  
PageContainer.propTypes = {
}

export default PageContainer