
/**
 * Define app styles here
 * these styles will de defaults for theme provided to createMuiTheme API
 */
const theme =  {
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: 'roboto',
        fontWeightMedium: 500,
        body1: {
            // Styles inside body1 will be inherited by all the children
            fontSize: 13,
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
        },
        subtitle1: {
            fontSize: 12,
        },
        button: {
            fontStyle: 'normal',
        },
        span: {
            fontSize: 60
        },
    },
    shape: {
        borderRadius: 3
    },
    
    // FIXME: snackbar 
    zIndex: {
        modal: 1300,
        snackbar: 1400,
        drawer: 1,
        appBar: 1100,
        mobileStepper: 1000,
        tooltip: 1500,
    },
    
    palette: {
        secondary: {
            main: '#f44336',
        },
        text: {
            primary: '#161616'
        },

        //   color: {
        //     paper: 'purple'
        //   },
        //   text: {
        //       primary: 'purple'
        //   }
        primary: {
            main: '#1C5BFF',
            light: "#1C2833",
            dark: "#1C5BFF",
            medium: '#766dff',
            contrastText: "#fff",
        },
      // type: 'dark', // Switching the dark mode on is a single property value change.
    },
    // Override Material components css here
    // NOTE: these are applied as global css
    overrides: {
        MuiBackdrop: {
            root: {
                // For blur dialog back drop
                background: 'rgba(28, 40, 51, 0.8)',
                backdropFilter: 'blur(14px)',
                /* Note: backdrop-filter has minimal browser support */
            }
        },
        MuiDialogActions: {
            root: {
                background: 'linear-gradient(180deg, rgba(237, 246, 255, 0.12) 0%, #FAF7F7 100%)'
            }
        },
        // Style sheet name ⚛️
        MuiInputBase: {
        // Name of the rule
            input: {
                fontSize: 14
            },

            root: {
                color: 'inherit'
            }
        },
        MuiButton: {
            root: {
                color: '#5E5E5E',
                borderRadius: 2
            }
        },
        
        MuiPaper: {
            root: {
                color: 'inherit'
            }
        },

        MuiTableCell: {
            body: {
                color: 'inherit'
            },
            head: {
                fontSize: 15,
                fontWeight: 700
            }
        },

        MuiFormLabel: {
            root: {
                color: 'inherit'
            }
        },

        MuiTab: {
            wrapper: {
                fontWeight: 700
            },
        }
    }
  }

export default theme