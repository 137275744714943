import React, { createContext, useReducer } from 'react';
import QueryMutations from './QueryMutations'

/* Define a context and a reducer for updating the context */
const QueryContext = createContext();

const initialState = {
    queryString: ''
};

const queryReducer = (state, action) => {
    switch (action.type) {
        case QueryMutations.SET_QUERY_STRING:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const QueryProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        queryReducer,
        initialState
    );

    return (
        <QueryContext.Provider value={[state, dispatch]}>
            {children}
        </QueryContext.Provider>
    );
};

export default QueryContext