import { useState, useEffect } from 'react'

const useDecodeJWTHook = (jwt) => {
    //TODO: do we need to have token data in state here ?
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        // Decode JWT
        if (jwt) decodeJWT(jwt)
    }, [jwt])

    function decodeJWT(jwt) {
        let jwtData = jwt.split('.')[1]
        let decodedJwtJsonData = window.atob(jwtData)
        let decodedJwtData = JSON.parse(decodedJwtJsonData)
        setPermissions(decodedJwtData.resource_access)
    }

    return { permissions }
    
}

export default useDecodeJWTHook