import React from "react";
import "./App.css";

import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import AppRouter from "./Routes/AppRouter";
import { ThemeProvider } from "@material-ui/styles";
import ThemeConfig from "./theme/Typography";
import { GlobalStateProvider } from "./Context/GlobalStateContext";
import { QueryProvider } from "./Context/QueryContext";
import { I18nProvider } from "@lingui/react";
import catalogEn from './locales/en/messages.js'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const catalogs = { en: catalogEn };

// auth
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
    },

    loader: {
        alignSelf: "center",
        textAlign: "center",
        margin: "auto",
    },

    progressContainer: {
        height: "100%",
        display: "flex",
    },
}));

const theme = createMuiTheme(ThemeConfig);

const App = () => {
    const classes = useStyles(theme);

    /**
     * Wrapper for providers
     * @param {*} props children
     */
    const Providers = (props) => {
        return (
            <>
                <I18nProvider language="en" catalogs={catalogs}>
                    <GlobalStateProvider>
                            {props.children}
                    </GlobalStateProvider>
                </I18nProvider>
            </>
        );
    };

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Providers>
                    <div className={classes.root}>
                        <ThemeProvider theme={theme}>
                            <QueryProvider>
                                <AppRouter />
                            </QueryProvider>
                        </ThemeProvider>
                    </div>
                </Providers>
            </MuiPickersUtilsProvider>
        </>
    );
};

export default App;
