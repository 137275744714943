import React from "react";
import ReactDOM from "react-dom";
import "./layout.scss";
import "./index.css";
import "./tabs.scss";
import "./dropdown.scss";
import "./animate.css";
import "./waves.scss";
import "./alerts.scss";
import "./base.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
        document.getElementById("root")
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (navigator.serviceWorker) {
    navigator.serviceWorker.register("worker.js").then(function (reg) {
        console.log("[Companion]", "Service worker registered!");
        console.log(reg);
    });
}
