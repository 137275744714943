import React, { useState, useEffect, useContext } from 'react'
import GlobalStateContext from '../Context/GlobalStateContext'
/**
 * @returns {Boolean} - weather permission is allow or not
 */
const dataObject = {
    "ic_management": "IC_MANAGEMENT"
}

const usePermissions = () => {
    const {state, dispatch} = useContext(GlobalStateContext)
    const [permissions, setPermissions] = useState({})

    useEffect(() => {
        if (state.orgSettings.permissions) {
            let obj = {}
            state.orgSettings.permissions.map((permision) => {
                obj[dataObject[permision]] = true
            })
            setPermissions(obj)
        }
    }, [state.orgSettings])
    return permissions
}



export default usePermissions;