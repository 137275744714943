import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { withOidcSecure } from "@axa-fr/react-oidc-context";
import { Route, Redirect, Switch } from 'react-router-dom';
import useDecodeJWTHook from "../Hooks/useDecodeJWTHook";
import useRedirect from "../Hooks/useRedirect";
import usePermissions from "../Hooks/usePermissions";


const RouteRedirect = (props) => {
    const { oidcUser } = useReactOidc();
    const permissions = usePermissions()

    const { routes } = useRedirect(permissions)

    if(!permissions.IC_MANAGEMENT) return null
    
    return (
        <Switch>
            {routes.map((route) =>
                // We need to redirect not allowed routes to device page
                route.redirect ? (
                    <Redirect key={route.path} from={route.path} to="/summary" />
                ) : (
                    <Route
                        exact={route.exact}
                        key={route.path}
                        path={route.path}
                        component={route.component}
                    />
                )
            )}
        </Switch>
    );
};

export default withOidcSecure(RouteRedirect);
