import React, { useCallback, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Drawer, ListItem, ListItemIcon, List, Tooltip } from '@material-ui/core'
import { VisibilityRounded, DnsRounded, GroupRounded, BackupRounded } from '@material-ui/icons'
import { useReactOidc, withOidcSecure } from '@axa-fr/react-oidc-context'
import ConnectIcon from "../assets/images/connectIcon.png"
import deviceIcon from "../assets/images/mobiles.png"
import useComponentMountedHook from '../Hooks/useComponentMountedHook';
import { useAxios } from '../core/useAxios';
import { NavLink } from 'react-router-dom';
import { getSettings } from "./Apis"
import GlobalStateContext from '../Context/GlobalStateContext';
import GlobalStateMutations from '../Context/GlobalStateMutations';
import usePermissions from '../Hooks/usePermissions';
const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },

    paperDrawer: {
        overflow: 'visible !important',
        // boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)',
        borderRight: 0
    },

    appDrawerButton: {
        zIndex: 3,
        border: '3px solid #fff',
        margin: '10px 10px 0',
        position: 'absolute',
        right: -26,
        background: '#fff',
        '&:hover': {
            background: '#d1ceff'
        },
        boxShadow: '0 2px 5px rgba(0,0,0,.16), 0 2px 10px rgba(0,0,0,.12)'
    },

    buttonRoot: {
        padding: 2
    },

    listItemIcon: {
        color: '#385169',
        minWidth: 0
    },

    listGutter: {
        padding: 18,
    },

    listRoot: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
        }
    },

    isActive: {
        background: '#FAF7F7'
    },

    broadCastIcon: {
        height: 24
    },

    connectIcon: {
        height: 24,
        width: 24,
    }

}));

const drawerWidth = 60;

const AppDrawer = (props) => {
    const { oidcUser } = useReactOidc();
    const permissions = usePermissions()
    const { dispatch } = useContext(GlobalStateContext)

    const classes = useStyles()

    const axiosInstance = useAxios(
        process.env.REACT_APP_HTTP_API_ROR_URL,
        oidcUser.access_token
    );

    let { isComponentMounted } = useComponentMountedHook(axiosInstance);

    useEffect(() => {
        if (isComponentMounted) {
            getSettings(axiosInstance).then(({ data }) => {
                dispatch({
                    type: GlobalStateMutations.SET_ORG_SETTINGS,
                    payload: data.settings
                })
            })
        }
    }, [isComponentMounted])

    const CustomLink = (props) => {
        return (
            <NavLink
                {...props}
                activeClassName={classes.isActive}
            >{props.children}</NavLink>
        )
    }

    const AdapterLink = React.forwardRef((props, ref) => <CustomLink innerRef={ref} {...props} />);
    {/* <RouteLink to="/messenger">Topics</RouteLink> */ }

    if (!permissions) return null

    return (
        <Drawer
            variant="permanent"
            className={classes.drawer}
            classes={{
                paper: clsx(classes.paperDrawer)
            }}>
            <div className={classes.toolbar} />
            <List disablePadding>
                {permissions.IC_MANAGEMENT &&
                    <Tooltip title="Summary" placement="right">
                        <ListItem component={AdapterLink} to="/summary" classes={{ gutters: classes.listGutter, root: classes.listRoot }}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}><VisibilityRounded /></ListItemIcon>
                        </ListItem>
                    </Tooltip>
                }
                {permissions.IC_MANAGEMENT &&
                    <Tooltip title="Organizations" placement="right">
                        <ListItem component={AdapterLink} to="/organizations" classes={{ gutters: classes.listGutter, root: classes.listRoot }}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}><GroupRounded /></ListItemIcon>
                        </ListItem>
                    </Tooltip>
                }
                {permissions.IC_MANAGEMENT &&
                    <Tooltip title="Resellers" placement="right">
                        <ListItem component={AdapterLink} to="/resellers" classes={{ gutters: classes.listGutter, root: classes.listRoot }}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}><img src={ConnectIcon} className={classes.connectIcon} /></ListItemIcon>
                        </ListItem>
                    </Tooltip>
                }
                {permissions.IC_MANAGEMENT &&
                    <Tooltip title="Devices" placement="right">
                        <ListItem component={AdapterLink} to="/devices" classes={{ gutters: classes.listGutter, root: classes.listRoot }}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}><img src={deviceIcon} className={classes.connectIcon} /></ListItemIcon>
                        </ListItem>
                    </Tooltip>
                }
                {permissions.IC_MANAGEMENT &&
                    <Tooltip title="Plans" placement="right">
                        <ListItem component={AdapterLink} to="/plans" classes={{ gutters: classes.listGutter, root: classes.listRoot }}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}><DnsRounded /></ListItemIcon>
                        </ListItem>
                    </Tooltip>
                }
                {permissions.IC_MANAGEMENT &&
                    <Tooltip title="Apps" placement="right">
                        <ListItem component={AdapterLink} to="/apk" classes={{ gutters: classes.listGutter, root: classes.listRoot }}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}><BackupRounded /></ListItemIcon>
                        </ListItem>
                    </Tooltip>
                }
            </List>
        </Drawer>
    )
}

export default withOidcSecure(AppDrawer)