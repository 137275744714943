/**
 * A mixin that provides common functionality
 * which can be used in places where we make XHR requests.
 */

import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import GlobalStateContext from '../Context/GlobalStateContext'
import GlobalStateMutations from '../Context/GlobalStateMutations'

export const useAxios = (baseURL, keycloak) => {

    const { dispatch } = useContext(GlobalStateContext)

    const [axiosInstance, setAxiosInstance] = useState({})

    useEffect(() => {
        const instance = axios.create({
            baseURL,
            headers: {
                Authorization: keycloak ? `Bearer ${keycloak}` : undefined
            }
        })

        setAxiosInstance({ instance })

        instance.interceptors.response.use(function (response) {
            // using data sent with request i.e toast message
            // FIXME: this only works if request has no params present
            // let { data } = response.config
            // if (data) toggleSnackbar(true, data)
            // TODO: global level success response depending on type of request
            // Do something with response data
            return response;
        }, function (error) {
            // Global error toast
            let setError = error.response ? (error.response.data.error_message ? error.response.data?.error_message : error.response.data?.errors) : 'Something went wrong!';
            toggleSnackbar(true, setError, 'error')
            // Do something with response error
            return Promise.reject(error);
        });

        function toggleSnackbar(isVisible, message, variant = "success") {
            dispatch({
                type: GlobalStateMutations.SHOW_SNACKBAR,
                payload: {
                    isVisible: isVisible,
                    message: message,
                    variant: variant,
                }
            });
        }

        return () => {
            setAxiosInstance({})
        }
    }, [keycloak])

    return axiosInstance.instance
}
