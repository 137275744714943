const fetchOrgsByPage = async (axiosInstance, page, pageSize) => await (
	axiosInstance.get(`/api/v1/orgs?page=${page}&pageSize=${pageSize}`)
)

const getOrgsUserCount = async (axiosInstance, orgId) => await (
	axiosInstance.get(`/api/v1/orgs/${orgId}/users-count`)
)

const getOrgIntent = async (axiosInstance, orgId) => await (
	axiosInstance.get(`/api/v1/ic/organizations/${orgId}.json`)
)

const getentitlements = async (axiosInstance, orgId) => await (
	axiosInstance.get(`/api/v1/org-entitlements/org/${orgId}`)
)

const getentitlementDetails = async (axiosInstance, orgId, featureCode) => await (
	axiosInstance.get(`/api/v1/org-entitlements/org/${orgId}/app/ONETEAM/feature/${featureCode}/usages`)
)

const getChannelDetails = async (axiosInstance, orgId, channelId) => await (
	axiosInstance.get(`/api/v1/channels/${channelId}/orgs/${orgId}`)
)

const getOwnerSessions = async (axiosInstance, orgId, userId) => await (
	axiosInstance.get(`/api/v1/im-users/${orgId}/${userId}/sessions`)
)

const getAllOwners = async (axiosInstance) => await (
	axiosInstance.get(`/api/v1/orgs/owners`)
)

const checkValidLicense = async (axiosInstance, orgId) => await (
	axiosInstance.get(`/api/v1/licenses/org/${orgId}/has-valid-license`)
)

const getOrgsDetails = async (axiosInstance, orgId) => await (
	axiosInstance.get(`/api/v1/users/org/${orgId}/account-owner`)
)

const createOrder = async (axiosInstance, orgId, data) => await (
	axiosInstance.post(`/api/v1/orders/org/${orgId}/custom-price`, data)
)

const getUserProfile = async (axiosInstance, userId) => await (
	axiosInstance.get(`/api/v1/users/${userId}/profile`)
)

const getConsumerProfile = async (axiosInstance, orgId, userId) => await (
	axiosInstance.get(`/api/v1/orgs/${orgId}/users/${userId}/profile`)
)

const getOrganization = async (axiosInstance, orgId) => await (
	axiosInstance.get(`/api/v1/orgs/${orgId}`)
)

const fetchLicenses = async (axiosInstance, orgId, params) => await (
	axiosInstance.get(`/api/v1/licenses/org/${orgId}?${params}`)
)

const fetchLicenseDetail = async (axiosInstance, orgId, licenseNumber) => await (
	axiosInstance.get(`/api/v1/licenses/${licenseNumber}/org/${orgId}`)
)

const getProratedLicenseDetails = async (axiosInstance, orgId, params) => await (
	axiosInstance.get(`/api/v1/ic/organizations/${orgId}/prorated_license_details?${params}`)
)

const addLicenseExtendDetails = (axiosInstance, orgId, licenseNumber, data) => {
	try {
		let response = axiosInstance.post(`/api/v1/licenses/${licenseNumber}/org/${orgId}/extensions`, data)
		return response;
	} catch (error) {
		return error;
	}
}

const revokeLicense = (axiosInstance, licenseNumber, orgId) => {
	try {
		let response = axiosInstance.delete(`/api/v1/licenses/${licenseNumber}/org/${orgId}`)
		return response;
	} catch (error) {
		return error;
	}
}

const fetchOrders = async (axiosInstance, orgId) => await (
	axiosInstance.get(`/api/v1/orders/org/${orgId}`)
)

const fetchOrderDetail = async (axiosInstance, orgId, orderNumber) => await (
	axiosInstance.get(`/api/v1/orders/${orderNumber}/org/${orgId}`)
)

const getOrgOwners = async (axiosInstance, params) => await (
	axiosInstance.get(`/api/v1/ic/organizations.json?` + params)
)

const getSummary = async (axiosInstance, params) => await (
	axiosInstance.get(`/api/v1/ic/organizations/summary.json?` + params)
)

const getOrgUsers = async (axiosInstance, orgId, params) => await (
	axiosInstance.get(`/api/v1/ic/organizations/${orgId}/users.json?` + params)
)

const updateEmail = async (axiosInstance, orgId, userId, param) => await (
	axiosInstance.put(`/api/v1/orgs/${orgId}/users/${userId}/owner`, param)
)

const createLicense = async (axiosInstance, orgId, data) => await (
	axiosInstance.post(`/api/v1/licenses/org/${orgId}/paid-license`, data)
)

const createAddOnLicense = async (axiosInstance, orgId, data) => await (
	axiosInstance.post(`/api/v1/licenses/org/${orgId}/paid-add-on-license`, data)
)
export const getSettings = async (axiosInstance) => await (
	axiosInstance.get(`/api/v1/settings.json`)
)

export default {
	fetchOrgsByPage,
	fetchLicenses,
	fetchLicenseDetail,
	addLicenseExtendDetails,
	revokeLicense,
	fetchOrders,
	fetchOrderDetail,
	getOrgsUserCount,
	getOrgIntent,
	checkValidLicense,
	getOrgsDetails,
	getOwnerSessions,
	getAllOwners,
	getOrganization,
	getentitlements,
	createOrder,
	getUserProfile,
	getentitlementDetails,
	getConsumerProfile,
	getChannelDetails,
	getOrgOwners,
	getSummary,
	getOrgUsers,
	updateEmail,
	createLicense,
	createAddOnLicense,
	getProratedLicenseDetails,
	getSettings
}
