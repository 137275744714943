import React, { useEffect, useState } from "react";
// import { Router } from '@reach/router'
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import oidcConfiguration from "../configuration";
import {
    AuthenticationProvider,
    oidcLog,
    InMemoryWebStorage
} from "@axa-fr/react-oidc-context";
import SessionRedirect from "../components/Authentication/SessionRedirect";
import CustomCallback from "../Pages/CustomCallback";
import NavigationBar from "../components/NavigationBar";
import AppDrawer from "../components/AppDrawer";
import RouteRedirect from "./RouteRedirect";

import { BrowserRouter as Router } from "react-router-dom";
import AccessTokenError from "../components/Authentication/AccessTokenError";
import AuthenticatingProgress from "../components/Authentication/AuthenticatingProgress";

const useStyles = makeStyles((theme) => ({
    content: {
        position: "relative",
        flexGrow: 1,
        padding: theme.spacing(3),
        "-webkit-box-ordinal-group": 3,
        "-ms-flex-order": 2,
        order: 2,
        '-webkit-box-flex': 1,
        '-ms-flex': 1,
        height: '100%',
        '& div[tabindex="-1"]': {
            height: "100%",
        },
        marginLeft: 65,
        paddingTop: 28
    },

    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    rootRouter: {
        flexGrow: 1,
    },
}));

const drawerWidth = 240;


const AppRouter = (props) => {
    
    const [accessTokenRenewError, setAccessTokenRenewError] = useState(false);
    
    const AccessTokenRenewError = function(data) {
        setAccessTokenRenewError(true);
    }

    useEffect(()=> { 
        document.addEventListener("AccessTokenRenewError", data => AccessTokenRenewError(data))

        return function cleanup() {
            document.removeEventListener('AccessTokenRenewError', AccessTokenRenewError);
        }
    }, []);

    const { open } = props;
    const classes = useStyles();
    
    return (
        <main
            role="main"
            className={clsx(classes.content, {
                [classes.appBarShift]: open,
            })}>
            <AuthenticationProvider
                notAuthenticated={SessionRedirect}
                notAuthorized={SessionRedirect}
                sessionLostComponent={SessionRedirect}
                authenticating={AuthenticatingProgress}
                configuration={oidcConfiguration}
                loggerLevel={oidcLog.DEBUG}
                isEnabled={true}
                callbackComponentOverride={CustomCallback}
                UserStore={InMemoryWebStorage}>
                <Router>
                    {/* FIXME: how we can use secured component inside React's context api and start using this global context*/}
                    {/* As we want to access auth api ie logout, we need to wrap navbar inside auth component */}
                    <AppDrawer />
                    <NavigationBar />
                    <RouteRedirect />
                </Router>
            </AuthenticationProvider>
            { accessTokenRenewError && <AccessTokenError /> } 
        </main>
    );
};


export default AppRouter;
